import type { Observable} from 'rxjs';
import { catchError, from, map, of } from 'rxjs';

import { UMSound } from '../index';

import { Player } from './Player';
import type { UMSoundOutputType} from './UMSoundOutputType';
import type { UMSoundResult} from './UMSoundResult';
import { numberToUMSoundResult } from './UMSoundResult';

export class Sound {
  address: bigint;

  constructor(address: bigint) {
    this.address = address;
  }

  getAddress(): bigint {
    return this.address;
  }

  createPlayer$(): Observable<Player> {
    return from(UMSound.UMSound_createPlayer({umSoundAddress: this.address})).pipe(
      map((result) => {
        return new Player(result.address);
      }),
      catchError((error) => {
        console.error('Failed to create player:', error);
        return of(null);
      })
    );
  }

  init$(audioType: UMSoundOutputType): Observable<UMSoundResult> {
    return from(UMSound.UMSound_init({address: this.address, audioType: audioType})).pipe(
      map((result) => {
        return numberToUMSoundResult(result.umSoundResult);
      })
    );
  }
}
