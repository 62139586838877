import { registerPlugin } from '@capacitor/core';

import type { UMSoundPlugin } from './definitions';

const UMSound = registerPlugin<UMSoundPlugin>('UMSound', {
  web: () => import('./web').then(m => new m.UMSoundWeb()),
  electron: () => (window as any).CapacitorCustomPlatform.plugins.UMSound,
});

export * from './definitions';
export * from './umsound/Player';
export * from './umsound/Sound';
export * from './umsound/UMSoundModule';
export * from './umsound/UMSoundResult';
export * from './umsound/UMSoundOutputType';
export * from './umsound/ScratchInformation.interface'
export { UMSound };
