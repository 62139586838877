import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { WaveformCache } from '../../../main/waveform/WaveformCache';
import { PitchMode, PitchModes } from '../../../models/pitch-mode';
import { Track } from '../../../music-archive/track';
import {
  cueAction,
  decreaseLoopBeatAction,
  increaseLoopBeatAction,
  loadIntoPlayerAction,
  loadIntoPlayerSuccessfullAction,
  playAction,
  setZoomAction,
  reloadWaveformCacheSuccessfulAction,
  scratchAction,
  scratchEndAction,
  scratchStartAction,
  setBpmAction,
  setCurrentScratchingPositionAction,
  setPitchAction,
  setPitchModeAction,
  setTimeAction,
  setTimeUM6Action,
  setTrackTitleUM6Action,
  setWasPlayingAction,
  startEndOfSongDisplayAnimationAction,
  stopEndOfSongDisplayAnimationAction,
  switchActivePlayerAction,
  switchBpmTpmAction,
  togglePitchModeAction,
  togglePlayedToSyncToAction,
  setLoopSuccessfulAction,
  unloadFileFromPlayerAction,
  loopInAction,
  loopOutAction,
  toggleLoopAction,
  updateWaveformDataAction,
  loadIntoPlayerFailedAction,
} from '../actions/player.actions';
import { ScratchingInformation } from '../../interfaces/scratching.interface';

export interface PlayerState {
  isPlaying: boolean;
  isLoading: boolean;
  time: number;
  remainingTime: number;
  bpm: number;
  progress: number;
  track: Track;
  waveformData: WaveformCache;
  loopBeat: number;
  loopDrawRange: { start: number; end: number; drawing: boolean };
  loopActive: boolean;
  playerID: number;
  // playerID of the player where the current player should sync to when the sync button is pressed
  playerIDToSyncTo: number;
  // the pitch setting of the pitch slider (in %)
  pitch: number;
  pitchMode: PitchMode;
  isEndOfSongAlmostReached: boolean;
  isPlayerActive: boolean;
  key: string;
  isShowingTpm: boolean;
  songLength: number;
}

export const playerReducerInitialState: PlayerState = {
  isPlaying: false,
  isLoading: false,
  songLength: 0,
  time: 0,
  remainingTime: 0,
  bpm: 0,
  progress: 0,
  track: null,
  waveformData: null,
  loopActive: false,
  loopBeat: 2,
  loopDrawRange: null,
  playerID: -1,
  playerIDToSyncTo: null,
  pitch: 0,
  pitchMode: PitchModes.PITCH,
  isEndOfSongAlmostReached: false,
  isPlayerActive: false,
  key: null,
  isShowingTpm: false,
};

export const playerReducer = createReducer(
  playerReducerInitialState,

  on(loadIntoPlayerAction, (state, action): PlayerState => {
    if (!checkPlayerID(action, state)) return state;

    return {
      ...state,
      track: null,
      time: 0,
      isLoading: true,
      remainingTime: 0,
      progress: 0,
    };
  }),

  on(loadIntoPlayerSuccessfullAction, (state, action): PlayerState => {
    if (!checkPlayerID(action, state)) return state;

    return {
      ...state,
      track: action.track,
      songLength: action.track.length,
      time: 0,
      isLoading: false,
      remainingTime: action.track.length,
      progress: 0,
      waveformData: action.waveformData,
    };
  }),
  on(loadIntoPlayerFailedAction, (state, action): PlayerState => {
    if (!checkPlayerID(action, state)) return state;
    return {
      ...state,
      track: null,
      songLength: 0,
      time: 0,
      isLoading: false,
      remainingTime: 0,
      progress: 0,
      waveformData: null,
    };
  }),
  on(playAction, (state, action): PlayerState => {
    if (!checkPlayerID(action, state)) return state;
    return {
      ...state,
      isPlaying: !state.isPlaying,
    };
  }),
  on(cueAction, (state, action) => getCueState(state, action)),
  on(increaseLoopBeatAction, (state, action) => {
    if (!checkPlayerID(action, state)) return state;
    if (state.loopBeat < 16) {
      return {
        ...state,
        loopBeat: state.loopBeat * 2,
      };
    } else {
      return {
        ...state,
      };
    }
  }),
  on(decreaseLoopBeatAction, (state, action) => {
    if (!checkPlayerID(action, state)) return state;
    if (state.loopBeat > 1 / 16) {
      return {
        ...state,
        loopBeat: state.loopBeat / 2,
      };
    } else {
      return {
        ...state,
      };
    }
  }),
  on(setLoopSuccessfulAction, (state, action) => {
    if (!checkPlayerID(action, state)) return state;
    return {
      ...state,
      loopDrawRange: { start: action.start, end: action.end, drawing: action.loop },
    };
  }),
  on(loopInAction, (state, action) => {
    if (!checkPlayerID(action, state)) return state;
    return {
      ...state,
      loopInTime: action.time,
    };
  }),
  on(loopOutAction, (state, action) => {
    if (!checkPlayerID(action, state)) return state;
    return {
      ...state,
      loopOutTime: action.time,
    };
  }),
  on(toggleLoopAction, (state, action) => {
    if (!checkPlayerID(action, state)) return state;
    return {
      ...state,
      loopActive: !state.loopActive,
    };
  }),
  on(setTimeAction, (state, action) => {
    if (!checkPlayerID(action, state)) return state;

    let trackLength = getTrackLength(state, action.playerID);

    if (trackLength <= 0) {
      trackLength = 60 * 3 * 1000;
    }

    if (action.time > trackLength) {
      return getCueState(state, action);
    } else {
      return {
        ...state,
        time: action.time,
        remainingTime: trackLength - action.time,
        progress: action.time / trackLength,
      };
    }
  }),
  on(setTimeUM6Action, (state, action): PlayerState => {
    if (!checkPlayerID(action, state)) return state;
    return {
      ...state,
      time: action.time,
      remainingTime: action.remainingTime,
    };
  }),
  on(setTrackTitleUM6Action, (state, action): PlayerState => {
    if (!checkPlayerID(action, state)) return state;
    const coverUrl = action.coverAsBase64 === 'null' ? null : action.coverAsBase64;
    const track = new Track(null, action.artist, action.title, 0, action.trackNumber, action.bpm, 0, coverUrl);
    return {
      ...state,
      track: track,
    };
  }),
  on(setPitchAction, (state, action): PlayerState => {
    if (!checkPlayerID(action, state)) return state;
    return {
      ...state,
      pitch: action.value,
    };
  }),
  on(togglePitchModeAction, (state, action): PlayerState => {
    if (!checkPlayerID(action, state)) return state;
    return {
      ...state,
      pitchMode: state.pitchMode == PitchModes.TEMPO ? PitchModes.PITCH : PitchModes.TEMPO,
    };
  }),
  on(togglePlayedToSyncToAction, (state, action): PlayerState => {
    return {
      ...state,
      playerIDToSyncTo: state.playerIDToSyncTo++ % 2,
    };
  }),
  on(setPitchModeAction, (state, action): PlayerState => {
    return {
      ...state,
      pitchMode: action.pitchMode,
    };
  }),
  on(startEndOfSongDisplayAnimationAction, (state, action): PlayerState => {
    if (!checkPlayerID(action, state)) return state;
    return {
      ...state,
      isEndOfSongAlmostReached: true,
    };
  }),
  on(stopEndOfSongDisplayAnimationAction, (state, action): PlayerState => {
    if (!checkPlayerID(action, state)) return state;
    return {
      ...state,
      isEndOfSongAlmostReached: false,
    };
  }),
  on(switchActivePlayerAction, (state, action): PlayerState => {
    if (action.playerIDToActive !== state.playerID && action.playerIDToDeactivate !== state.playerID) return state;

    let isPlayerActivated;
    if (action.playerIDToActive === state.playerID) {
      isPlayerActivated = true;
    }
    if (action.playerIDToDeactivate === state.playerID) {
      isPlayerActivated = false;
    }

    return {
      ...state,
      isPlayerActive: isPlayerActivated,
    };
  }),
  on(setBpmAction, (state, action): PlayerState => {
    if (!checkPlayerID(action, state)) return state;
    return {
      ...state,
      bpm: action.bpm,
    };
  }),
  on(switchBpmTpmAction, (state, action): PlayerState => {
    if (!checkPlayerID(action, state)) return state;
    return {
      ...state,
      isShowingTpm: action.command === 'tpm',
    };
  }),
  on(setCurrentScratchingPositionAction, (state, action) => {
    if (!checkPlayerID(action, state)) return state;
    return {
      ...state,
      currentScratchingPosition: action.positionInDegree,
    };
  }),
  on(unloadFileFromPlayerAction, (state, action) => {
    if (!checkPlayerID(action, state)) return state;
    return {
      ...state,
      track: null,
      time: 0,
      isLoading: false,
      remainingTime: 0,
      progress: 0,
      isEndOfSongAlmostReached: false,
      key: null,
    };
  }),
  on(scratchStartAction, (state, action) => {
    if (!checkPlayerID(action, state)) return state;
    return {
      ...state,
    };
  }),
  on(scratchEndAction, (state, action) => {
    if (!checkPlayerID(action, state)) return state;
    return {
      ...state,
    };
  }),
  on(scratchAction, (state, action) => {
    if (!checkPlayerID(action, state)) return state;
    return {
      ...state,
    };
  }),
  on(setWasPlayingAction, (state, action) => {
    if (!checkPlayerID(action, state)) return state;
    return {
      ...state,
    };
  }),
  on(setZoomAction, (state, action): PlayerState => {
    if (!checkPlayerID(action, state)) return state;
    return {
      ...state,
    };
  }),
  on(reloadWaveformCacheSuccessfulAction, (state, action): PlayerState => {
    if (!checkPlayerID(action, state)) return state;
    return {
      ...state,
      waveformData: action.waveformCache,
    };
  }),
  on(updateWaveformDataAction, (state, action): PlayerState => {
    if (!checkPlayerID(action, state)) return state;
    return {
      ...state,
      waveformData: action.waveformCache,
    };
  })
);

function getCueState(state: PlayerState, action: any) {
  if (!checkPlayerID(action, state)) return state;
  return {
    ...state,
    isPlaying: false,
    time: action.playerID,
    remainingTime: getTrackLength(state, action.playerID),
    progress: 0,
  };
}

function getTrackLength(state: PlayerState, playerID: number) {
  const track = state.track[playerID];
  if (!track) {
    return 0;
  } else {
    return track.length;
  }
}

function checkPlayerID(action: any, state: PlayerState): boolean {
  return action.playerID == state.playerID;
}

// All getter of general state

const playerStates: any = {
  player1: createFeatureSelector<PlayerState>('player1'),
  player2: createFeatureSelector<PlayerState>('player2'),
  player3: createFeatureSelector<PlayerState>('player3'),
  player4: createFeatureSelector<PlayerState>('player4'),
};
// console.log('>>> playerStates',playerStates);

export const isPlaying = (playerID: number) => createSelector(getPlayerState(playerID), (state: PlayerState) => state.isPlaying);

export const isLoading = (playerID: number) => createSelector(getPlayerState(playerID), (state: PlayerState) => state.isLoading);

export const isPlayButtonDisabled = (playerID: number) =>
  createSelector(getPlayerState(playerID), (state: PlayerState) => state.track === null);

export const getTime = (playerID: number) => createSelector(getPlayerState(playerID), (state: PlayerState) => state.time);

export const getRemainingTime = (playerID: number) => createSelector(getPlayerState(playerID), (state: PlayerState) => state.remainingTime);

export const getProgress = (playerID: number) => createSelector(getPlayerState(playerID), (state: PlayerState) => state.progress);

export const getTrack = (playerID: number) => createSelector(getPlayerState(playerID), (state: PlayerState) => state.track);

export const getWaveformCache = (playerID: number) => createSelector(getPlayerState(playerID), (state: PlayerState) => state.waveformData);
export const getLoopBeatRange = (playerID: number) => createSelector(getPlayerState(playerID), (state: PlayerState) => state.loopBeat);
export const getLoopDrawRange = (playerID: number) => createSelector(getPlayerState(playerID), (state: PlayerState) => state.loopDrawRange);
export const isLoopActive = (playerID: number) => createSelector(getPlayerState(playerID), (state: PlayerState) => state.loopActive);

export const getKey = (playerID: number) => createSelector(getPlayerState(playerID), (state: PlayerState) => state.key);
export const getPitch = (playerID: number) => createSelector(getPlayerState(playerID), (state: PlayerState) => state.pitch);
export const getPitchMode = (playerID: number) => createSelector(getPlayerState(playerID), (state: PlayerState) => state.pitchMode);
export const getPlayerIDToSyncTo = (playerID: number) =>
  createSelector(getPlayerState(playerID), (state: PlayerState) => (state.playerID == 1 ? 2 : 1));

export function getPlayerState(playerID): any {
  const s = playerStates['player' + playerID];
  return s;
}

export const isEndOfSongAlmostReached = (playerID: number) =>
  createSelector(getPlayerState(playerID), (state: PlayerState) => state.isEndOfSongAlmostReached);
export const isPlayerActive = (playerID: number) => createSelector(getPlayerState(playerID), (state: PlayerState) => state.isPlayerActive);
export const getBpm = (playerID: number) => createSelector(getPlayerState(playerID), (state: PlayerState) => state.bpm);
export const isShowingTpm = (playerID: number) => createSelector(getPlayerState(playerID), (state: PlayerState) => state.isShowingTpm);

export const getSongLength = (playerID: number) => createSelector(getPlayerState(playerID), (state: PlayerState) => state.songLength);
