import type { Observable, Subject} from 'rxjs';
import { from, map, take } from 'rxjs';

import { ScratchingInformation, UMSound } from '../index';
import { Effects } from './Effects';

import { UMSoundResult} from './UMSoundResult';
import { numberToUMSoundResult } from './UMSoundResult';

export interface Beat {
  keydown: number,
  magnitude: number,
  phase: number,
  isInterpolated: boolean,
  salience: number,
  scoreBeat: number
}

export class Player {
  address: bigint;

  //general
  private isPlaying: boolean = false;
  private position: number;
  private levels: Float32Array;
  private updateInterval: NodeJS.Timeout;
  private msPerPixel: number = 100 / 12;
  private pitch: number = 1;
  private timeStretch: number = 1;

  private effects: Effects;

  //loop
  loopInPos: number;
  loopOutPos: number;
  loopActive: boolean = false;
  private beatRange: number = 2;
  private beatStartTimeMs: number = null;

  //waveform
  waveformCache: Int8Array;
  beats: Beat[];
  private lastScratchInfo: ScratchingInformation;
  private playingBeforeScratch: boolean;
  private pitchBeforeScratch: number;
  private updateWaveData$: Subject<Int8Array>;
  // private updatePosition$: Subject<number>;
  private wave: {
    start: number,
    stepSize: number,
    bufferedParts: number
  } = {start: 4096, stepSize: 1024, bufferedParts: 0};

  private bytePerPx: number = 8;

  constructor(address: bigint) {
    this.address = address;
    this.position = 0;
    this.levels = new Float32Array(2);
    this.effects = new Effects();
  }

  startUpdateLoop(intervalMs: number): void {
    this.updateInterval = setInterval(() => {
      this.updatePosition();
      this.updateLevels();
    }, intervalMs);
  }

  registerWaveDataUpdate(updateWaveData$: Subject<Int8Array>): void {
    this.updateWaveData$ = updateWaveData$;
  }

  stopUpdateLoop(): void {
    clearInterval(this.updateInterval);
    this.updateInterval = null;
  }

  updatePosition(): void {
    UMSound.Player_getPosition({address: this.address }).then((result) => {
      this.position = result.position;
    });

    let posMs = this.pcmToMs(this.position);
    let currentPart = Math.ceil((posMs * this.bytePerPx * 1/this.msPerPixel) / this.wave.stepSize);    

    if( this.wave.bufferedParts - currentPart < 15 ){
      this.wave.bufferedParts++;
      this.updateWaveData().pipe(
        take(1)
      ).subscribe();
    }
  }

  updateWaveData(): Observable<null> {
    // let startPoint = (this.wave.bufferedParts * this.wave.stepSize + this.wave.start) * 1/this.msPerPixel * this.bytePerPx;
    return this.getWaveData$().pipe(
      map((waveData) => {
        this.updateWaveData$.next(waveData);
        return null;
      })
    );
  }

  setMsPerPixel(msPerPixel: number): void {
    if(this.msPerPixel != msPerPixel) {
      this.wave.bufferedParts = 0;
      this.waveformCache = null;
    }
    this.msPerPixel = msPerPixel;
  }

  getMsPerPixel(): number {
    return this.msPerPixel;
  }

  updateLevels(): void {
    UMSound.Player_getLevels({address: this.address }).then((result) => {
      this.levels = result.levels;
    });
  }

  getAddress(): bigint {
    return this.address;
  }

  initialize_easy$(umSoundAddress: bigint): Observable<UMSoundResult> {
    console.log('initialize_easy$ ahc');
    return from(UMSound.Player_initialize_easy({playerAddress: this.address, umSoundAddress: umSoundAddress})).pipe(
      map((result) => {
        return numberToUMSoundResult(result.umSoundResult);
      })
    );
  }

  loadSound$(fileName: string, webRoute: string): Observable<UMSoundResult> {
    return from(UMSound.Player_loadSound({address: this.address, fileName: fileName, webRoute})).pipe(
      map((result) => {
        return numberToUMSoundResult(result.umSoundResult);
      })
    );
  }

  play(): UMSoundResult {
    this.isPlaying = !this.isPlaying;
    return UMSound.Player_play({address: this.address}).umSoundResult;
  }

  stop(): null {
    this.isPlaying = false;
    return UMSound.Player_stop({address: this.address});
  }

  setPitch(value: number): null {
    this.pitch = value;
    return UMSound.Player_setPitch({address: this.address, value: value});
  }

  setTimeStretch(value: number): null {
    this.timeStretch = value;
    return UMSound.Player_setTimeStretch({address: this.address, value: this.timeStretch});
  }

  setVolume(value: number): UMSoundResult {
    return UMSound.Player_setVolume({address: this.address, value: value}).umSoundResult;
  }

  getLevels(): Float32Array {
    return this.levels;
  }

  getWaveData$(): Observable<Int8Array> {
    return from(UMSound.Player_getWaveData({address: this.address})).pipe(
      map((result) => {
        this.addWaveformPart(result.waveData);
        return this.waveformCache;
      })
    );
  }

  addWaveformPart(newPart: Int8Array): void {
    if (this.waveformCache) {
      let combined = new Int8Array(this.waveformCache.length + newPart.length);
      combined.set(this.waveformCache);
      combined.set(newPart, this.waveformCache.length);
      this.waveformCache = combined;
    } else {
      this.waveformCache = newPart;
    }
  }

  getBeats$(): Observable<Beat[]> {
    return from(UMSound.Player_getBeats({address: this.address})).pipe(
      map((result) => { 
        this.beats = this.castToBeats(result.beats);
        return this.beats;
      })
    );
  }

  getPosition(): number {
    return this.position;
  }

  startScratch(scratchInfo: ScratchingInformation): UMSoundResult {
    this.lastScratchInfo = scratchInfo;
    this.playingBeforeScratch = this.isPlaying;
    this.pitchBeforeScratch = this.pitch;
    if(!this.isPlaying) {
      //needs to stop when not moving
      this.play();
      this.setPitch(0);
    }
    return UMSound.Player_setScratchIntensity({address: this.address, intensity: 1}).umSoundResult;
  }

  scratch(currScratchInfo: ScratchingInformation): UMSoundResult {
    if (this.lastScratchInfo.posX) {
      let deltaX = this.lastScratchInfo.posX - currScratchInfo.posX;
      //invert the direction so scratching dir feels natural
      deltaX *= -1;
      const deltaT = this.lastScratchInfo.time - currScratchInfo.time;

      //formula comes from calc of ratio between normal and needed speeds
      let speedFactor = this.msPerPixel / (deltaT / deltaX);

      if (speedFactor > 25) {
        speedFactor = 25;
      } else if (speedFactor < -25) {
        speedFactor = -25;
      }

      this.lastScratchInfo = currScratchInfo;

      UMSound.Player_setPitch({address: this.address, value: speedFactor});
      return UMSound.Player_setScratchIntensity({address: this.address, intensity: speedFactor}).umSoundResult;
    }
    return UMSoundResult.INTERNAL_ERROR;
  }

  endScratch(): UMSoundResult {
    if (!this.playingBeforeScratch) {
      this.play();
    }
    this.setPitch(this.pitchBeforeScratch);
    return UMSound.Player_setScratchIntensity({address: this.address, intensity: 0}).umSoundResult;
  }

  setScratchIntensity(intensity: number): UMSoundResult {
    return UMSound.Player_setScratchIntensity({address: this.address, intensity: intensity}).umSoundResult;
  }

  setResonance(value: number): UMSoundResult {
    this.effects.resonance.value = value;
    this.effects.resonance.active = true;
    return UMSound.Player_setResonance({address: this.address, value: value, active: this.effects.resonance.active}).umSoundResult;
  }

  setCutoff(value: number): UMSoundResult {
    this.effects.cutoff.value = value;
    this.effects.cutoff.active = true;
    return UMSound.Player_setCutoff({address: this.address, value: value, active: this.effects.cutoff.active}).umSoundResult;
  }

  setFlanger(depth: number, rate: number, mix: number): UMSoundResult {
    this.effects.flanger.depth = depth;
    this.effects.flanger.rate = rate;
    this.effects.flanger.mix = mix;
    this.effects.flanger.active = true;
    return UMSound.Player_setFlanger({address: this.address, depth: depth, rate: rate, mix: mix, active: this.effects.flanger.active}).umSoundResult;
  }

  setEQHigh(value: number): UMSoundResult {
    this.effects.eqHigh.value = value;
    this.effects.eqHigh.active = true
    return UMSound.Player_setEQHigh({address: this.address, value: value, active: this.effects.eqHigh.active}).umSoundResult;
  }

  setEQMid(value: number): UMSoundResult {
    this.effects.eqMid.value = value;
    this.effects.eqMid.active = true;
    return UMSound.Player_setEQMid({address: this.address, value: value, active: this.effects.eqMid.active}).umSoundResult;
  }

  setEQLow(value: number): UMSoundResult {
    this.effects.eqLow.value = value;
    this.effects.eqLow.active = true;
    return UMSound.Player_setEQLow({address: this.address, value: value, active: this.effects.eqLow.active}).umSoundResult;
  }

  killResonance(): UMSoundResult {
    this.effects.resonance.active = !this.effects.resonance.active;
    return UMSound.Player_setResonance({address: this.address, value: this.effects.resonance.value, active: !this.effects.resonance.active}).umSoundResult;
  }

  killFlanger(): UMSoundResult {
    let mix = this.effects.flanger.mix;
    let rate = this.effects.flanger.rate;
    let depth = this.effects.flanger.depth;
    this.effects.flanger.active = !this.effects.flanger.active;
    return UMSound.Player_setFlanger({address: this.address, mix: mix, rate: rate, depth: depth, active: !this.effects.flanger.active}).umSoundResult;
  }

  killCutoff(): UMSoundResult {
    this.effects.cutoff.active = !this.effects.cutoff.active;
    return UMSound.Player_setCutoff({address: this.address, value: this.effects.cutoff.value, active: !this.effects.cutoff.active}).umSoundResult;
  }

  killEQHigh(): UMSoundResult {
    this.effects.eqHigh.active = !this.effects.eqHigh.active;
    return UMSound.Player_setEQHigh({address: this.address, value: this.effects.eqHigh.value, active: !this.effects.eqHigh.active}).umSoundResult;
  }

  killEQMid(): UMSoundResult {
    this.effects.eqMid.active = !this.effects.eqMid.active;
    return UMSound.Player_setEQMid({address: this.address, value: this.effects.eqMid.value, active: !this.effects.eqMid.active}).umSoundResult;
  }

  killEQLow(): UMSoundResult {
    this.effects.eqLow.active = !this.effects.eqLow.active;
    return UMSound.Player_setEQLow({address: this.address, value: this.effects.eqLow.value, active: !this.effects.eqLow.active}).umSoundResult;
  }

  setLoopIn(pos: number): void {
    this.loopInPos = pos; 
  }

  setLoopOut(pos: number): {started: boolean} {
    this.loopOutPos = pos + 1000; //+1000 so it detects the loop

    if(!this.loopActive) {
      this.startLoop(this.loopInPos, this.loopOutPos);
    } else {
      this.endLoop();
    }
    return {started: this.loopActive};
  } 

  startLoop(startPos: number, endPos: number): UMSoundResult {
    console.log('startLoop', startPos, endPos);
    this.loopActive = true;
    return UMSound.Player_startLoop({address: this.address, startPos: startPos, endPos: endPos}).umSoundResult;
  }

  endLoop(): UMSoundResult {
    this.loopActive = false;
    return UMSound.Player_endLoop({address: this.address}).umSoundResult;
  }

  loopBeat(): {startTime: number, endTime: number, started: boolean} {
    let time = this.pcmToMs(this.getPosition());
    if(this.loopActive) {
      let startTime = this.beatStartTimeMs;
      this.beatStartTimeMs = null;
      this.endLoop();
      return {startTime: startTime, endTime: time, started: this.loopActive}
    } else {
      const startInd = this.findCurrentBeatIndex(time);
      const { startTime, endTime } = this.calculateStartEndTimes(startInd, time, this.beatRange);
      this.beatStartTimeMs = startTime;
      this.startLoop(Math.round(this.msToPcm(startTime)), Math.round(this.msToPcm(endTime)));

      return {startTime: startTime, endTime: endTime, started: this.loopActive}
    }
  }

  setLoopBeatRange(beatRange: number) {
    console.log('setLoopBeatRange', beatRange, this.loopActive, this.beatStartTimeMs );
    if(this.beatRange != beatRange && this.loopActive && this.beatStartTimeMs != null) {
      const startInd = this.findCurrentBeatIndex(this.beatStartTimeMs);
      const { startTime, endTime } = this.calculateStartEndTimes(startInd, this.beatStartTimeMs, beatRange);
      this.startLoop(Math.round(this.msToPcm(startTime)), Math.round(this.msToPcm(endTime)));
    }
    
    this.beatRange = beatRange;
  }

  private findCurrentBeatIndex(time: number): number | undefined {
    for (let i = this.beats.length - 1; i >= 0; i--) {
      if (this.beats[i].keydown * 1000 < time) {
        return i;
      }
    }
    return undefined;
  }

  pcmToMs(pcm: number): number {
    return (pcm / this.getSampleRate()) * 1000;
  }

  msToPcm(ms: number): number {
    return (ms / 1000) * this.getSampleRate();
  }

  private calculateStartEndTimes(startInd: number, time: number, beatLength: number) {
    console.log(this.beats);
    let startTime = this.beats[startInd].keydown * 1000;
    let endTime: number;

    //loop over whole beats or just specific parts in one loop
    if (beatLength >= 1) {
      endTime = this.beats[startInd + beatLength].keydown * 1000;
    } else {
      const beatSize = this.beats[startInd + 1].keydown * 1000 - startTime;
      const partSize = beatSize * beatLength;

      for (let i = 1 / beatLength - 1; i >= 0; i--) {
        if (startTime + partSize * i < time) {
          startTime = startTime + partSize * i;
          endTime = startTime + partSize;
          break;
        }
      }
    }

    return { startTime, endTime };
  }

  openVideo$(filename: string): Observable<UMSoundResult> {
    return from(UMSound.Player_openVideo({address: this.address, filename: filename})).pipe(
      map((result) => {
        return numberToUMSoundResult(result.umSoundResult);
      })
    );
  }

  showVideo(x: number, y: number, width: number, height: number): UMSoundResult {
    return UMSound.Player_showVideo({address: this.address, x: x, y: y, width: width, height: height}).umSoundResult;
  }

  getSampleRate(): number {
    return 44100;
  }

  private castToBeats(data: Float64Array): Beat[] {
    let beats: Beat[] = [];
    let currentBeat: Beat;
    let j = 0;

    //ugly but no better way for now
    for (let i = 0; i < data.length / 6; i++) {
      j = i * 6;
      currentBeat = {
        keydown: data[j],
        magnitude: data[j + 1],
        phase: data[j + 2],
        isInterpolated: !!data[j + 3],
        salience: data[j + 4],
        scoreBeat: data[j + 5],
      };
      if (currentBeat.keydown > 0.0001 || currentBeat.keydown < 0.0001) {
        beats.push(currentBeat);
      }
    }
    return beats;
  }
}
