import { Component, OnInit, ElementRef, ViewChild, HostListener, Input, AfterViewInit, ChangeDetectionStrategy, OnDestroy, inject } from '@angular/core';
import { WaveformSegment } from '../waveform-segment';
import { HttpClient } from '@angular/common/http';
import { WaveformCache } from '../WaveformCache';
import { Store } from '@ngrx/store';
import { WaveformCacheI } from '../WaveformCacheI';
import { of, timer, Observable, Subject, BehaviorSubject, Subscriber, Subscription } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { WaveformSegmentModel } from '../WaveformSegmentModel';
import { WaveformSegmentComponent } from '../waveform-segment/waveform-segment.component';
import { getTime } from '../../../core/redux/reducers/player.reducer';
import { ScratchingInformation } from '../../../core/interfaces/scratching.interface';
import { setZoomAction, scratchAction, scratchEndAction, scratchStartAction } from '../../../core/redux/actions/player.actions';

@Component({
    selector: 'dual-waveform-v2',
    templateUrl: './dual-waveform.component.html',
    styleUrls: ['./dual-waveform.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: false
})

/**
 * ROUTE: "waveform/dual2" || "waveform/dual"
 *
 * Second dual-waveform implementation
 * v1 was flickiering due to live reactClear and repaint every timer-tick
 * This implementation uses one canvas per segment instead on one canvas per waveform.
 * Each segment is an instance of {@link WaveformSegmentComponent} which is initialized via a template (ng-for)
 * from a list of {@link WaveformSegmentModel} instances.
 *
 * The segment components are moved across the background via "translateX(...)" css binding
 * updated via segmentPositionCanvasPxTranlate-property of segment-component
 * @see drawWaveform()
 * @see drawOrPositionSegment()
 */
export class DualWaveformComponent implements OnInit, AfterViewInit, OnDestroy {
  private store = inject(Store);

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {
    const http = inject(HttpClient);

    this.http = http;

    this.timeSub1 = this.time1$.subscribe((time) => {
      this.playerPositionMs = time;
      this.updateSegmentsPosition();
    });
    this.timeSub2 = this.time2$.subscribe((time) => {
      this.playerPositionMs = time;
      this.updateSegmentsPosition();
    });
  }
  @Input('data2') set cacheInfo2(data: WaveformCache) {
    this._cacheInfo2 = data;
    // this.initSegments();
  }

  get cacheInfo2() {
    return this._cacheInfo2;
  }

  private upperPlayerID = 1;
  private lowerPlayerID = 2;

  // background canvas
  @ViewChild('bgCanvas', { static: false })
  bgCanvas: ElementRef;

  // forground canvas
  @ViewChild('fgCanvas', { static: false })
  fgCanvas: ElementRef;

  @ViewChild('waveformContainer', { static: false }) waveformContainer: ElementRef;

  @ViewChild('p1Canvas', { static: false })
  p1Canvas: ElementRef;
  @ViewChild('p2Canvas', { static: false })
  p2Canvas: ElementRef;

  @ViewChild('segmentContainer', { static: false })
  segmentContainer: ElementRef;

  @ViewChild(DualWaveformComponent, { static: false })
  msPxRatioProvider: DualWaveformComponent = this;

  @Input() debugMode = true;

  protected _cacheInfo1$ = new BehaviorSubject(null);

  // unused for now, SHOULD WE USED BEHAVIOUR SUBJECT OR THIS??? does behavioursubject work well with @Input?
  _cacheInfo2: WaveformCache = null;

  public waveformWidth$ = new BehaviorSubject(800); //CURRENTLY UNUSED
  public waveformHeight$ = new BehaviorSubject(140); //CURRENTLY UNUSED

  private readonly RESIZE_DEBOUNCE_DELAY = 250; // ms

  segmentCount = 14; // segment count per playerlist
  segmentWidth = 128;//128; // 512;

  // maximum number prerendered segments on the left/right side outside of the main canvas waveform
  rightMaxPrerendered = 1;
  leftMaxPrerendered = 0;

  // zoom == millisecond to pixel ratio, this is later controlled by user, for now hardset to value for cache
  // hardcoded value specific to testWaveformCache/data1.json waveformCache 
  // of the song C:\\Program Files\\UltraMixer6\\The_Admirals_featuring_Seraphina_Bass!Man2010_UltraMixerEdit.mp4
  // TODO: later this is used as a zoom factor and as input for PlayerFmod4.calcWaveform() to get waveform-data specific to this value
  ZOOM_FACTOR = Math.sqrt(2);
  defaultZoom = 100 / 12;
  msPxRatio = this.defaultZoom;
  minZoom = this.defaultZoom / 4;
  maxZoom = this.defaultZoom * 4;

  time1$: Observable<number> = this.store.select(getTime(1));
  time2$: Observable<number> = this.store.select(getTime(2));
  timeSub1: Subscription;
  timeSub2: Subscription;
  // current play position in ms, should later be aquired from player
  playerPositionMs = 0;

  // position of play-line on main canvas, length of canvas is irrelevant, only playpos relative to canvas-start (0) matters
  private playPosPx = 200; // will be changed in ngOnInit() to middle of canvas

  // cached segments - this will be of constant size once segments are initialized since no segments get added or deleted; just reused
  // filled in this.initSegments()
  // contains all segments including those
  segmentsPool: WaveformSegmentModel[] = new Array();

  // used with template in
  // displaySegmentList: WaveformSegmentModel[] = new Array();
  displaySegmentListObservable$: BehaviorSubject<WaveformSegmentModel[]> = new BehaviorSubject<WaveformSegmentModel[]>(new Array());

  http: HttpClient;

  // cache for dragscrolling waveform, since only one songs waveform can be scrolled at a time this might be used both for p1 and p2
  dragStart: number; //DnD related
  timeStart: number;
  lastSend: Date;

  private paused = true;
  private playSub;
  @Input('data1') 
  set CacheInfo1(data: WaveformCache) {
    this._cacheInfo1$.next(data);
  }

  getCacheInfo1(): WaveformCache {
    return this._cacheInfo1$.getValue();
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    this.timeSub1.unsubscribe();
    this.timeSub2.unsubscribe();
  }

  ngAfterViewInit(): void {
    console.log('ngAfterViewInit called');

    this.redrawBgCanvas();
    this.redrawFgCanvas();

    console.log('chacheInfo1:' + this.getCacheInfo1());
    this._cacheInfo1$.subscribe((wfCache: WaveformCache) => {
      console.log("detected cache change");
      if (wfCache && wfCache != null && !wfCache.added) {
        this.upperPlayerID = wfCache.playerID;
        console.log('about to init segments:' + wfCache);
        this.initSegments(wfCache);
        this.redrawBgCanvas();
        this.redrawFgCanvas();      
      
      } else if(wfCache && wfCache != null && wfCache.added) {
        this.upperPlayerID = wfCache.playerID;
        console.log("added inside wf component");
        this.segmentsPool.forEach((segment) => {
          segment._waveformCache$.next(wfCache);
        })
      }
      else {
        console.log('cache empty:' + this.getCacheInfo1());
        this.clearSegments();
      }
    });

    const func = (entries) => {
      for (const entry of entries) {
        if (entry.target === this.waveformContainer.nativeElement) {
          this.onResize(entry.contentRect.width, entry.contentRect.height);
          this.onCanvasResize();
        }
        if (entry.target === this.waveformContainer.nativeElement) {
          this.onCanvasResize();
        }
      }
    }; // debounce, this.RESIZE_DEBOUNCE_DELAY);

    const ro = new ResizeObserver(func);

    // ro.observe(this.p1Canvas.nativeElement);
    ro.observe(this.waveformContainer.nativeElement);



    if (this.debugMode) {
      console.log('debugMode:' + this.debugMode);
      this.loadDummyWaveformCache();
    }
  }

  private onCanvasResize() {
    // console.log('>>> onCanvasResize');
    this.redrawBgCanvas();
    this.redrawFgCanvas();
  }

  private onResize(width: number, height: number) {
    if (width <= 0 || height <= 0) return;
    console.log('>>> onResize: waveform size', width, height); 
    this.playPosPx = width / 2;
    this.waveformWidth$.next(width);
    this.waveformHeight$.next(height);
  }

  handlePlay() {
    this.paused = false;
    if (!this.playSub) {
      this.playSub = timer(0, 8)
        .pipe(
          tap(() => {
            if (!this.paused) {
              this.playerPositionMs += 8;
              this.updateSegmentsPosition();
            }
          })
        )
        .subscribe();
    }
  }

  handlePause() {
    this.paused = true;
  }

  @HostListener('mousedown', ['$event'])
  mouseDown(event: MouseEvent) {
    if ((event.target as HTMLElement).id === 'p1Canvas') {
      // console.log('mousedown on p1Canvas -> remember position %s', event.screenX);
      this.dragStart = event.screenX;
      // todo: stop player before dragging, resume on drop if was playing before
    }
  }
  
  @HostListener('touchstart', ['$event'])
  touchStart(event: TouchEvent) {
    console.log(event.touches.length);
    if(event.touches.length == 1)
    {
      let scratchInfo : ScratchingInformation= {
        time: new Date().getTime(),
        posX: event.touches[0].screenX
      } 
      //TODO: add check wether it is upper or lower waveform
      this.store.dispatch(scratchStartAction({ playerID: this.upperPlayerID, scratchingInformation: scratchInfo }));
    }
  }

  last = new Date().getTime();

  @HostListener('mouseup', ['$event'])
  mouseUp(event: MouseEvent) {
    // console.log("mouseupEventP1", event);
    this.dragStart = null;
  }
  
  @HostListener('touchend', ['$event'])
  touchEnd(event: TouchEvent) {
    //TODO: add check wether it is upper or lower waveform
    this.store.dispatch(scratchEndAction({playerID: this.upperPlayerID}));
  }

  @HostListener('mousemove', ['$event'])
  mouseMove(event: MouseEvent) {
    if (this.dragStart) {
      const deltaX = event.screenX - this.dragStart;
      // console.log('mouse up and was dragging -> move delta px %s',deltaX);
      const deltaPlayPosMs = deltaX * this.msPxRatio * -1;
      this.playerPositionMs += deltaPlayPosMs;
      this.updateSegmentsPosition(); // todo: trigger this by playerPos value change
      this.dragStart = event.screenX;
    }
  }

  @HostListener('touchmove', ['$event'])
  touchMove(event: TouchEvent) {
    let scratchInfo : ScratchingInformation= {
      time: new Date().getTime(),
      posX: event.touches[0].screenX,
    } 
    if(scratchInfo.time - this.last > 5) {
      //TODO: add check wether it is upper or lower waveform
      this.store.dispatch(scratchAction({ playerID: this.upperPlayerID, scratchingInformation: scratchInfo, msPerPx: this.msPxRatio }));
      this.last = scratchInfo.time;
    }
  }

  dummyFillP1P2Canvas() {
    const p1Canvas: HTMLCanvasElement = this.p1Canvas.nativeElement;
    const p1Ctx: CanvasRenderingContext2D = p1Canvas.getContext('2d');

    p1Ctx.lineWidth = 2;
    p1Ctx.strokeStyle = 'red';
    p1Ctx.beginPath();
    p1Ctx.moveTo(0, 0);
    p1Ctx.lineTo(p1Canvas.width, p1Canvas.height);
    p1Ctx.stroke();

    const p2Canvas: HTMLCanvasElement = this.p2Canvas.nativeElement;
    const p2Ctx: CanvasRenderingContext2D = p2Canvas.getContext('2d');

    p2Ctx.lineWidth = 2;
    p2Ctx.strokeStyle = 'red';
    p2Ctx.beginPath();
    p2Ctx.moveTo(0, 0);
    p2Ctx.lineTo(p2Canvas.width, p2Canvas.height);
    p2Ctx.stroke();
  }

  redrawFgCanvas() {
    const fgCanvas: HTMLCanvasElement = this.fgCanvas.nativeElement;
    const fgCtx: CanvasRenderingContext2D = fgCanvas.getContext('2d');
    fgCtx.strokeStyle = 'gold';
    // fgCtx.strokeRect(0, fgCanvas.height / 2 + 1, fgCanvas.width, 0);
    fgCtx.moveTo(0, fgCanvas.height / 2 + 1);
    fgCtx.lineTo(fgCanvas.width, fgCanvas.height / 2 + 1);
    fgCtx.stroke();

    fgCtx.strokeStyle = 'white';
    fgCtx.strokeRect(fgCanvas.width / 2 + 1, 0, 1, fgCanvas.height);
    fgCtx.stroke();
  }

  redrawBgCanvas() {
    const bgCanvas: HTMLCanvasElement = this.bgCanvas.nativeElement;
    const bgCtx: CanvasRenderingContext2D = bgCanvas.getContext('2d');
    bgCtx.fillStyle = 'black';
    bgCtx.fillRect(0, 0, bgCanvas.width, bgCanvas.height);
  }

  /**
   * load dummy cache and initialize segments thereafter
   */
  loadDummyWaveformCache() {
    console.log('start loading dummy waveformCache from assets');
    this.http.get('./assets/testWaveformCache/data1.json').subscribe((data: WaveformCacheI) => {
      console.log('dummy Waveformcache loaded:' + data);
      // this.setCacheInfo1(new WaveformCache(data));
    });
  }

  movePos100ms() {
    this.playerPositionMs += 200;
    this.updateSegmentsPosition();
  }

  moveNeg100ms() {
    this.playerPositionMs -= 200;
    this.updateSegmentsPosition();
  }

  private setZoom(zoom: number) {
    this.msPxRatio = zoom;

    if(this.msPxRatio > this.maxZoom) {
      this.msPxRatio = this.maxZoom;
    }
    else if(this.msPxRatio < this.minZoom)
    {
      this.msPxRatio = this.minZoom;
    }
    else{
      this.store.dispatch(setZoomAction({playerID: this.upperPlayerID, msPxRatio: this.msPxRatio}));
    }
  }

  zoomIn() {
    this.setZoom(this.msPxRatio / this.ZOOM_FACTOR)
  }

  zoomOut() {
    this.setZoom(this.msPxRatio * this.ZOOM_FACTOR);
  }

  // initialise segments, set initial positions and draw on internalCanvases of segments, finally copy to p1Canvas
  initSegments(data: WaveformCache): void {
    this.clearSegments();

    /* #region START OF SEGMENTMODEL CREATION FOR LOOP */
    for (let i = 0; i < this.segmentCount; i++) {
      // create new segment
      const segmentModel = new WaveformSegmentModel(this.segmentWidth, this.waveformHeight$.getValue() / 2, {msPxRatio: this.msPxRatio});
      // paint segments with data
      // console.log("initsegment -> waveformCache: ", JSON.stringify(data));
      segmentModel._waveformCache$.next(data);
      segmentModel.idNumber = i;

      // set initial position by lineing segments up in main cache
      const mainCanvasSegmentPosPx = i * this.segmentWidth;
      // segments position in song in ms
      const segmentPosMs = (mainCanvasSegmentPosPx - this.playPosPx) * this.msPxRatio + this.playerPositionMs;
      segmentModel.setXPositionMs(segmentPosMs);
     //  console.log('set segment %s to position: %s', i, segmentModel.getXPositionMs());

      // add segment to pool. note that not all elements in the pool are included in the dom at all times
      this.segmentsPool.push(segmentModel); // look at drawOrPositionSegment() regarding segment initialization
    }
    /* #region END OF SEGMENTMODEL CREATION FOR LOOP */

    this.updateSegmentsPosition();
  }

  /**
   * clears waveform segments list, should usually only happen if a song is unloaded
   */
  clearSegments() {
    this.segmentsPool = [];
    this.displaySegmentListObservable$.next([]);
  }

  /**
   * this will reposition the segments on the canvas (to xPosPx in Canvas)
   * then the given Model will be included in displaySegmentListObservable$ if it is not already included
   *
   * this will lead to the creation of a waveformSegmentComponent via ng-for
   */
  repositionSegmentModelAndIncludeInList(
    // TODO: RENAME TO SOMETHING BETTER
    segmentModel: WaveformSegmentModel,
    xPosPx: number,
    yPosPx?: number
  ) {
    if (!yPosPx) {
      yPosPx = 0;
    }
    segmentModel.segmentPositionCanvasPx = xPosPx;
    // add model -> dom element will be created
    const list: WaveformSegmentModel[] = this.displaySegmentListObservable$.getValue();
    if (!list.includes(segmentModel)) {
      list.push(segmentModel);
    }
    this.displaySegmentListObservable$.next(list);
  }

  /**
   * repositions segments in dom via change of segmentModels
   * segments are repainted inside waveform-segment-component if their xPositionMs changes (that is to say they get used for a different part in the song)
   *
   * segmentModels and segments should get discarded and new ones created when a different cache is loaded
   */
  updateSegmentsPosition() {

    // declare some vars for use below
    const invalidSegments: WaveformSegmentModel[] = new Array<WaveformSegmentModel>();
    let lastValidSegmentEndMs = 0;
    let lastValidSegmentPosPx: number, firstValidSegmentPosPx: number;
    let firstValidSegmentStartMs: number = Number.MAX_VALUE;

    // set segments at correct position dependant on current play position
    // change segment position
    for (const segmentModel of this.segmentsPool) {
      const recalcedMainCanvasSegmentPosPx = (segmentModel.getXPositionMs() - this.playerPositionMs) / this.msPxRatio + this.playPosPx;

      // segments exit on left side -> invalidate
      if (!this.isValidSegmentPos(recalcedMainCanvasSegmentPosPx)) {
        invalidSegments.push(segmentModel);
      } else {
        //if the segmetns did not go out of frame on the right or the left (plus buffer)
        //  -> reposition it to the newly calculated px position
        this.repositionSegmentModelAndIncludeInList(segmentModel, recalcedMainCanvasSegmentPosPx, 0);

        //OK soo far we have positioned still valid segments according to where we are in the song
        // we have also added all invalid segments (outside the current to-display area as invalid) to a list

        // now depending on the change in play position we might have to fill some gaps on the left or right side where
        //new segments should show up

        // cache most left and most right valid segment.maxX in ms
        //this way we know where to append new segments
        const endMs = segmentModel.getXPositionMs() + this.segmentWidth * this.msPxRatio;
        if (lastValidSegmentEndMs < endMs) {
          // right side
          lastValidSegmentEndMs = endMs;
          lastValidSegmentPosPx = recalcedMainCanvasSegmentPosPx;
        }
        if (firstValidSegmentStartMs > segmentModel.getXPositionMs()) {
          // left side
          firstValidSegmentStartMs = segmentModel.getXPositionMs();
          firstValidSegmentPosPx = recalcedMainCanvasSegmentPosPx;
        }
      }
    }


    // instead of creating entirely new segment instances we are going to reuse the invalidated segments
    // fillReuseSegments(invalidSegments); // todo: move some code to such an function

    
    // IN THIS NEXT SECTION WE WILL REASSIGN EXISTING INVALID SEGMENTS TO REPRESENT NEW POSITIONS IN THE SONG 
    // THIS ONLY HAPPENDS IF THERE IS SOME FREE ROOM TO FILL LEFT ON THE LEFT OR RIGHT SIDE OF THE ALREADY EXISTING SEGMENTS

    // with this while loop we keep appending segments until there is no free space left or we run out of segments to reuse
    while (this.isRightSideFreeSpace(lastValidSegmentPosPx) && invalidSegments.length > 0) {
      const segmentModel = invalidSegments.pop();
      // segments position in song in ms
      const newPosMs = lastValidSegmentEndMs;
      
      // recalculate where the new segment should be placed in px
      const recalcedMainCanvasSegmentPosPx = (newPosMs - this.playerPositionMs) / this.msPxRatio + this.playPosPx;
      console.log('newPos for reused segment: %s ms   -> pos in canvasPx: %s', newPosMs, recalcedMainCanvasSegmentPosPx);

      // tell the segmentModel that it now represents a different part in the song !!!THIS WILL REPAINT THE SEGMENT!!!
      segmentModel.setXPositionMs(newPosMs);

      // this will add a component equlvaent to the model to the dom at the correct pixel position
      this.repositionSegmentModelAndIncludeInList(segmentModel, recalcedMainCanvasSegmentPosPx, 0);
      lastValidSegmentEndMs = lastValidSegmentEndMs + (this.segmentWidth * this.msPxRatio);
    }


    // now do the same thing we did above for the left side
    while (this.isLeftSideFreeSpace(firstValidSegmentPosPx) && invalidSegments.length > 0) {
      const segment = invalidSegments.pop();
      // segments position in song in ms
      const newPosMs = firstValidSegmentStartMs - /*i**/ this.segmentWidth * this.msPxRatio;
      // only for debugging
      const recalcedMainCanvasSegmentPosPx = (newPosMs - this.playerPositionMs) / this.msPxRatio + this.playPosPx;
      console.log('newPos for reused segment: %s ms   -> pos in canvasPx: %s', newPosMs, recalcedMainCanvasSegmentPosPx);
      segment.setXPositionMs(newPosMs);

      // draw segment on main canvas
      this.repositionSegmentModelAndIncludeInList(segment, recalcedMainCanvasSegmentPosPx, 0);
      firstValidSegmentStartMs = firstValidSegmentStartMs - (this.segmentWidth * this.msPxRatio);
    }
  }

  /** returns if a potential segment positioned at the provided position is valid or not
   * @param canvasPxPos some position in canvas pixel coordinate system (x)
   */
  private isValidSegmentPos(canvasPxPos: number): boolean {
    // left side invalid
    const leftSideValid: boolean = canvasPxPos + this.segmentWidth + this.leftMaxPrerendered * this.segmentWidth > 0;
    const rightSideValid: boolean = canvasPxPos < this.waveformWidth$.getValue() + this.rightMaxPrerendered * this.segmentWidth;

    return leftSideValid && rightSideValid;
  }

  private isRightSideFreeSpace(lastValidSegmentPosPx: number): boolean {
    return lastValidSegmentPosPx < this.waveformWidth$.getValue() + (this.rightMaxPrerendered - 1) * this.segmentWidth;
  }

  private isLeftSideFreeSpace(firstValidSegmentPosPx: number): boolean {
    return firstValidSegmentPosPx + this.leftMaxPrerendered * this.segmentWidth > 0;
  }
}
