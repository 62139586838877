import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { WaveformCache } from '../WaveformCache';
import { Beat } from '../WaveformCacheI';
import { Observable, Subscription } from 'rxjs';
import { getLoopDrawRange } from 'src/app/core/redux/reducers/player.reducer';
import { Store } from '@ngrx/store';
import { RGB } from '../../../core/interfaces/rgb.interface';

@Component({
    selector: 'waveform-segment',
    templateUrl: './waveform-segment.component.html',
    styleUrls: ['./waveform-segment.component.scss'],
    standalone: false
})
export class WaveformSegmentComponent implements OnInit, AfterViewInit, OnDestroy {
  private store = inject(Store);

  @Input('segmentPositionCanvasPx')
  set segmentPositionCanvasPx(newPosXCanvasPx) {
    this._segmentPositionCanvasPx = newPosXCanvasPx;
    /*console.log("set segmentPositionCanvasPx: %s", newPosXCanvasPx);
    console.log(
      "segmentPositionCanvasPxTranlate: ",
      this.segmentPositionCanvasPxTranlate
    );*/
  }

  get segmentPositionCanvasPx() {
    return this._segmentPositionCanvasPx;
  }

  get segmentPositionCanvasPxTranlate() {
    const rounded = Math.round(this._segmentPositionCanvasPx);
    const translateString = `translateX(${rounded}px)`;
    return translateString;
  }

  private get segmentCanvas() {
    return this.internalCanvasER.nativeElement;
  }

  private get xStartPx() {
    return Math.round(this.getXPositionMs() / this.msPxRatio);
  }

  private get xEndPx() {
    return Math.round(this.xStartPx + this.segmentCanvas.width);
  }

  loopRangeSub: Subscription;

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  // repaint on cacheChange/msPxRatio/xPositionMs
  // todo: repaint on width/Height change except initially
  public constructor() {
    // this.msPxRatioProvider = msPxRatioProvider;
    WaveformSegmentComponent.segmentCount++;

    this.loopRangeSub = this.loopRange$.subscribe((range) => {
      if (!range) return;
      if (this.needsLoopRepaint(range)) {
        if (this.isPartiallyInCanvas(this.msToPx(range.start), this.msToPx(range.end))) {
          this.repaint(this.waveformCache);
        }
      } else {
        if (this.isPartiallyInCanvas(this.msToPx(range.start), this.msToPx(range.end))) {
          this.drawLoopRange(range.start, range.end);
        }
      }
    });
    this.segmentIndex = WaveformSegmentComponent.segmentCount;


    console.log('called constructor of segment %s', this.segmentIndex);
  }

  loopRange$: Observable<{ start: number; end: number; drawing: boolean }> = this.store.select(getLoopDrawRange(1));

  private get msPxRatio(): number {
    //   console.log("msPxratio is", this.msPxRatioProvider.msPxRatio);
    return this.msPxRatioProvider.msPxRatio;
  }

  @Input('xPositionMs')
  public set xPositionPx(xPos: number) {
    this._xPositionMs = xPos;
    if (this.initPassed) {
      this.repaint(this.waveformCache);
    }
  }

  public get xPositionPx(): number {
    return this._xPositionMs;
  }
  static segmentCount = -1;

  @Input('width') width: number;
  @Input('height') height: number;

  // x position in ms in regards to the song -> requires different calculations to get to pixel-position in cache/in waveform
  // in cache this is simply xPositionMs / msPxRatio
  private _xPositionMs = 0;
  @Input('msPxRatioProvider')
  msPxRatioProvider: { msPxRatio: number };

  // cached value set by dual-waveform-component
  private _segmentPositionCanvasPx: number;

  @ViewChild('internalCanvasER', { static: false }) internalCanvasER: ElementRef;

  @Input('waveformCache')
  waveformCache: WaveformCache;

  // for debugging only
  segmentIndex = 0;

  // assinged in ngOnInit()
  // internalCtx: CanvasRenderingContext2D;

  // inialised on first getContext2D() call and cached
  private ctx2d: CanvasRenderingContext2D = null;

  private initPassed = false;
  ngOnInit() {
    console.log('called ngOnInit() of segment %s', this.segmentIndex);
  }

  ngAfterViewInit() {
    this.initPassed = true;
    this.repaint(this.waveformCache);
  }

  ngOnDestroy(): void {
    this.loopRangeSub.unsubscribe();
  }

  public getContext2D(): CanvasRenderingContext2D {
    if (this.ctx2d == null) {
      console.log('init ctx');
      this.ctx2d = this.segmentCanvas.getContext('2d');
    }
    return this.ctx2d;
  }

  public getXPositionMs() {
    return this._xPositionMs;
  }

  drawSegmentBackground() {
    /*this.getContext2D().fillStyle = "#ff0000";
    this.getContext2D().fillRect(0, 0, 512, 100);
    this.getContext2D().fill();*/

    this.getContext2D().clearRect(0, 0, this.segmentCanvas.width, this.segmentCanvas.height);
  }

  private repaint(data: WaveformCache) {
    if (!data) {
      console.error('data for segment %s is ', this.segmentIndex, data);
    }
    const byteArray: Int8Array = data.getByteArrayFromCacheObj();
    const beats = data.cache.beats;

    // copied value from WaveformSegmentBuffer.BYTES_PER_PIXEL in waveformcontrol6-fx
    const BYTES_PER_PIXEL = data.BYTES_PER_PIXEL;
    const lineWidth = 1;
    const pixelCount = data.pixelCount;

    this.drawSegmentBackground();

    // todo_impl: // Zwei duenne Linien fuer die echten Null-Positionen zeichnen, unabhaengig von der Silence.
    // drawTrackLimits()

    // start px in global

    const scaleVals = 1 / Math.sqrt(2);

    if (!(this.xEndPx < 0)) {
      // clear waveform first (drawSegmentBackground()), then only paint over where there is data
      // x - pixel position to paint
      // i - position in byteArray to get data from
      for (
        let x = this.xStartPx, i = this.xStartPx * BYTES_PER_PIXEL;
        i + 7 < byteArray.length && x < this.xEndPx;
        x++, i += BYTES_PER_PIXEL
      ) {
        if (x < 0) {
          continue;
        }

        const minValue = byteArray[i] * scaleVals - 0.5;
        const maxValue = byteArray[i + 1] * scaleVals + 0.5;

        const avgNegative = byteArray[i + 2] * scaleVals - 0.5;
        const avgPositive = byteArray[i + 3] * scaleVals + 0.5;

        const middle = this.segmentCanvas.height;
        const scale = this.segmentCanvas.height / 255.0;
        // Durch die Rundung werden die sehr kleinen Werte um 1 Pixel besser sichtbar.
        // Bei den negativen Werten erzeugt nur -0.5 symetrische Bilder, auch round() ist falsch.
        // const maxY1 = middle - (maxValue * scale); // top of line, 0.5  is like ceil
        // let minY2 = middle - (minValue * scale - 0.5); // bot of line, -0.5 is like floor
        // var lineH = maxY1 - minY2 + 1;//why +1?

        // calculate min max color from low, mid and high pass
        const lowPassAvg = Math.max(0, Math.min(255, byteArray[i + 4] * 2 + 0.5));
        const midPassAvg = Math.max(0, Math.min(255, byteArray[i + 5] * 2 + 0.5));
        const highPassAvg = Math.max(0, Math.min(255, byteArray[i + 6] * 2 + 0.5));
        const minMaxColorRGB: RGB = {
          r: Math.max(0.0, Math.min(255, lowPassAvg)),
          g: Math.max(0.0, Math.min(255, midPassAvg)),
          b: Math.max(0.0, Math.min(255, highPassAvg)),
          alpha: 255,
        };
        const minMaxColor = this.rgbToHex(minMaxColorRGB);

        //calculate average color by blending between main color (orange for now) and the min max color
        const mainColor = 'ffa500';
        let averageColor = this.blendBetweenTwoColors(minMaxColorRGB, this.hexToRgb(mainColor));

        // //draw the min max waveform
        const lineH = Math.abs(minValue * scale) + Math.abs(maxValue * scale) + 1; // why +1
        this.drawRectangle(x - this.xStartPx, middle, lineWidth, lineH, minMaxColor);

        //draw the average waveform
        const averageH = Math.abs(avgNegative) * scale + Math.abs(avgPositive) * scale;
        this.drawRectangle(x - this.xStartPx, middle, lineWidth, averageH, this.rgbToHex(averageColor));
      }

      this.drawBeats(this.xStartPx, this.xEndPx, beats, this.segmentCanvas.height);
    }

    if (environment.debugDualWaveform) {
      this.getContext2D().strokeStyle = '#00ffff55';
      this.getContext2D().lineWidth = 1;
      this.getContext2D().strokeRect(0, 0, this.segmentCanvas.width - 1, this.segmentCanvas.height);
      // this.getContext2D().stroke();

      this.getContext2D().fillStyle = 'red';
      this.getContext2D().font = 'italic 10pt Calibri';
      const text = 'segment ' + this.segmentIndex;
      this.getContext2D().fillText(text, 5, this.segmentCanvas.height - 10);
    }
  }

  private rgbToHex(color: RGB) {
    return '#' + ((1 << 24) | (color.r << 16) | (color.g << 8) | color.b).toString(16).slice(1);
  }

  private hexToRgb(hex: string): RGB {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
      alpha: 255,
    };
  }

  private drawRectangle(x: number, y: number, lineWidth: number, lineHeight: number, hexColor: string) {
    this.getContext2D().beginPath();
    this.getContext2D().rect(x, y, lineWidth, lineHeight * -1);
    this.getContext2D().fillStyle = hexColor;
    this.getContext2D().fill();
  }

  private drawBeats(startPx: number, endPx: number, beats: Beat[], middle: any) {
    let drawPos: number;

    beats?.forEach((beat) => {
      //calculate position in px from time
      drawPos = (beat.keydown * 1000) / this.msPxRatio;

      //just draw beats when they are in segment
      let isRightWindow = !(drawPos <= startPx || drawPos >= endPx || drawPos == 0);
      if (isRightWindow) {
        this.drawRectangle(drawPos - startPx, middle, 1, this.segmentCanvas.height, '0000FF');
      }
    });
  }

  private drawLoopRange(startMs: number, endMs: number) {
    let leftPos = this.msToPx(startMs);
    let rightPos = this.msToPx(endMs);

    //check which loop range needs to be drawn
    if (!(leftPos < this.xStartPx)) {
      this.drawRectangle(leftPos - this.xStartPx, this.segmentCanvas.height, 1, this.segmentCanvas.height, '#00FF00');
    }
    if (!(rightPos > this.xEndPx)) {
      this.drawRectangle(rightPos - this.xStartPx, this.segmentCanvas.height, 1, this.segmentCanvas.height, '#00FF00');
    }
  }

  private msToPx(ms: number): number {
    return ms / this.msPxRatio;
  }

  private isPartiallyInCanvas(leftPxX: number, rightPxX: number): boolean {
    return !(leftPxX > this.xEndPx || rightPxX < this.xStartPx);
  }

  private needsLoopRepaint(range: { start: number; end: number; drawing: boolean }) {
    return !range?.drawing;
  }

  private blendBetweenTwoColors(c0: RGB, c1: RGB): RGB {
    let c1Alpha = c1.alpha / 2.0;
    let totalAlpha = c0.alpha + c1Alpha;
    let weight0 = c0.alpha / totalAlpha;
    let weight1 = c1Alpha / totalAlpha;

    return {
      r: Math.min(weight0 * c0.r + weight1 * c1.r, 255),
      g: Math.min(weight0 * c0.g + weight1 * c1.g, 255),
      b: Math.min(weight0 * c0.b + weight1 * c1.b, 255),
    } as RGB;
  }
}
